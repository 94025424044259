#leaderboard {
    width: 100%;
    height: 100%;
    margin-top: 70px;

    ul {
        li {
            margin: 0.3rem 0;
        }
    }
}
