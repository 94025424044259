/* General classes */
.wrapper {
    margin: 0 $edgeBuffer;
    display: flex;
    flex-direction: column;
    text-align: center;

    // @include sm {
    //     max-width: 80vw;
    //     margin: 0 auto;
    //     // border: 5px solid grey;
    // }
    
    // @include md {
    //     // border: 5px solid green;
    // }

    // @include lg {
    //     // border: 5px solid orange;
    // }

    // @include xl {
    //     // border: 5px solid red;
    //     max-width: 60vw;
    // }
}

.regular-text {
    font-size: $regular;
}

.section-title {
    font-size: $larger;
    margin: 3rem 0 1.5rem 0;
}

.sub-title {
    font-size: $medium;
    margin: 0.6rem 0;
    text-align: left;
}

.breakline {
    width: 70vw;
    height: 1px;
    margin: 3rem auto;
    padding: 0;
    background-color: $grey;
}

a.regular-link {
    color: #b2abf8;
    font-size: initial;
    text-decoration: underline;
}

.page-title {
    font-size: $extraLarge;
    text-align: center;
}
