@keyframes slide-in {
    from {
        transform: rotate(20deg) scale(2);
        opacity: 0;
    }
}


#sidebar {
    background-color: $darker;
    width: 100%;
    height: 100%;
    top: 0;
    // left: 0;
    z-index: 1;
    position: fixed;
    // max-height: 1000px;
    max-width: 500px;

    div.container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // max-height: 1000px;
        max-width: 500px;
        margin: 70px 0 0 0;
        padding: 0;

        a {
            color: $light;
            padding: 3vh 2vw;
            font-size: $semilarge;
            margin: 0 1vw;

            animation-name: slide-in;
            animation-duration: 350ms;
        }
    }
}
