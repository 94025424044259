@import "_variables.scss";
@import "./_classes.scss";
@import "./SideBar.scss";
@import "./TopBar.scss";
@import "./Leaderboard.scss";

html {
    background: black !important;
    
    ::-webkit-scrollbar {
        overflow-x: hidden;
        display: none;
        visibility: hidden;
    }
}


/* lineup class and keyframes */
.line-up {
    animation: 2s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: translateY(0%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

body {
    margin: 0;
    background: black; // $darker;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    a {
        text-decoration: none;
    }
}

.app {
    background: $darker;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: $light;
    // overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.center-children {
    display: flex;
    justify-content: flex;
    align-items: flex;
}

// #App {
//     max-width: 100vw !important;
// }

// #home, #contact, #projects {
//     margin: $navbarHeight 0 0 0;
//     min-height: 100vh;
//     background-color: $dark;
//     padding: 0;
//     color: $light;

//     @include md {
//         margin-top: $navbarHeightLarge;
//     }
// }


// /* Links */
// a {
//     text-decoration: none;
//     color: $light;
//     font-size: 1.4rem;
// }


// /* Regular text */
// p {
//     line-height: 1.7rem;
//     text-align: left;
// }



/* Lists */
ul {
    padding: 0;
}

li {
    list-style: none;
}